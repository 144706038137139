import React from 'react'
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import { graphql } from 'gatsby'
import Places from '../components/Places/Places'
import PlacesByZipCode from '../components/Places/PlacesByZipCode'
import SEO from "../components/SEO"
import RehabSearch from "../components/Places/RehabSearch";
import ZipCodeSearch from "../components/Search/ZipCodeSearch";
import { BrowserRouter } from 'react-router-dom';

export const query = graphql`
query {
    defaultBcg: file(relativePath: {eq: "replace-house-sunlight.jpg"}) {
        childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`;

export default function placesbyzipcode({ data }) {
    return (
        <Layout>
            <SEO title="Places" description="Search Rehabs." />
            <StyledHero img={data.defaultBcg.childImageSharp.fluid}></StyledHero>
            {/*<Places />*/}
            {/*<BrowserRouter>*/}
            <PlacesByZipCode/>
            {/*</BrowserRouter>*/}
        </Layout>
    )
}
