import React, { Component, useEffect, useState  } from 'react'
import styles from "../../css/items.module.css"
import Title from "../Title"
// import { withRouter } from 'react-router-dom';
import List from '../List/List';
import withListLoading from '../List/WithListLoading';
import {FaSearch} from "react-icons/fa";
// import { createBrowserHistory } from 'history';
import { navigate } from "gatsby"

var axios = require("axios");


class PlacesByZipCode extends Component {


    constructor(props) {
        super(props);

        this.state = {
            zipcode: typeof window !== 'undefined'? window.history.state.zipcode: null,
            distance: typeof window !== 'undefined'? window.history.state.distance: null,
            errors: [],
            loading: false,
            repos:null,
            su: typeof window !== 'undefined'? window.history.state.su: null,
            dt: typeof window !== 'undefined'? window.history.state.dt: null,
            mh: typeof window !== 'undefined'? window.history.state.mh: null,
            hh: typeof window !== 'undefined'? window.history.state.hh: null,
            ot: typeof window !== 'undefined'? window.history.state.ot: null
        };
        // this.history = typeof window !== 'undefined' ? createBrowserHistory({ forceRefresh: true }) : null;
        // this.history = createBrowserHistory({ forceRefresh: true });

        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.location = null;
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     console.log('Thsi is the props', nextProps);
    //     this.setState({zipcode: nextProps});
    // }

    handleInputChange(event) {

        event.preventDefault();
        console.log(event.target.value)
        const query = event.target.value

        console.log('Thsi is teh query value'+ query + this.state.zipcode);
        navigate('/placesbyzipcode', {
            state: {
                zipcode: this.state.zipcode,
                distance: 15,
                su: this.state.su,
                dt: this.state.dt,
                mh: this.state.mh,
                hh: this.state.hh,
                ot: this.state.ot
            },
            replace: true
        });
        this.fetchTheData();
        // this.history.push({
        //     pathname: '/placesbyzipcode',
        //     state: {
        //         zipcode: this.state.zipcode,
        //         distance: this.state.distance,
        //         su: this.state.su,
        //         dt: this.state.dt,
        //         mh: this.state.mh,
        //         hh: this.state.hh,
        //         ot: this.state.ot
        //     }
        //
        // });

    }

    fetchTheData(){



            // console.log('Thsi is the props' + this.props.location.state.zipcode);
            // this.setState({zipcode: this.props.location.state.zipcode});

            this.setState({
                loading: true
            });
            const headers = { 'Content-Type': 'application/json', 'x-api-key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM' };
            const apiUrl = `https://xvobsixhna.execute-api.us-east-1.amazonaws.com/prod/`;

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'
                },
                mode: 'no-cors'
            };
            const payload = {
                "zipcode": this.state.zipcode,
                "distance": this.state.distance,
                "filters": {
                    "su": this.state.su,
                    "dt": this.state.dt,
                    "mh": this.state.mh,
                    "hh": this.state.hh,
                    "ot": this.state.ot
                }
            };
            axios.post(apiUrl,JSON.stringify(payload), config)
                .then((response) => {
                    console.log(response);
                    this.setState({
                        loading: false,
                        repos: response.data });

                })
                .catch((err) => {
                    console.log('error from API', err);
                });
    }

    componentDidMount() {



        // console.log('Thsi is the props' + this.props.location.state.zipcode);
        // this.setState({zipcode: this.props.location.state.zipcode});

            this.setState({
                loading: true
            });
            const headers = { 'Content-Type': 'application/json', 'x-api-key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM' };
            const apiUrl = `https://xvobsixhna.execute-api.us-east-1.amazonaws.com/prod/`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'
                },
            mode: 'no-cors'
        };
        const payload = {
            "zipcode": this.state.zipcode,
            "distance": this.state.distance,
            "filters": {
                "su": this.state.su,
                "dt": this.state.dt,
                "mh": this.state.mh,
                "hh": this.state.hh,
                "ot": this.state.ot
            }
        };
        axios.post(apiUrl,JSON.stringify(payload), config)
            .then((response) => {
                console.log(response);
                this.setState({
                    loading: false,
                    repos: response.data });

            })
            .catch((err) => {
                console.log('error from API', err);
            });
    }

    render() {

        const ListLoading = withListLoading(List);

        return (
            <section className={styles.tours}>
                <Title title="search results" subtitle="Rehab Centers" />
                <div className={styles.center}>
                    <div className={styles.wrapper}>
                        <div className={styles.address}>
                            <span>Zip Code: &nbsp;</span>
                    <input
                        className={styles.searchInput}
                        type="text"
                        value={this.state.zipcode}
                        onChange={e => this.setState({ zipcode: e.target.value })}
                        aria-label="Search"
                        placeholder="Type 5 digit Zip Code ..."
                    ></input>
                            <br/>
                            <span>Distance: &nbsp;</span>
                            <select id="distance"
                                    value={this.state.distance}
                                  onChange={e => this.setState({ distance: e.target.value })}
                            >
                                <option value="15" selected>15</option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                                <option value="69">60</option>
                            </select>
                            <br/><br/>
                            <div>
                                <button type="button" className={styles.logoBtn} onClick={this.handleInputChange}>
                                    <FaSearch className={styles["logo-icon"]}/> Filter
                                </button>
                            </div>
                        </div>
                    <div className={styles.features}>
                        <input type="checkbox" id="su"
                               onClick={e => this.setState({ su: e.target.checked })}
                               name="su" checked={this.state.su} value="su"/>
                        <label htmlFor="su"> Substance use treatment</label><br/>

                        <input type="checkbox" id="dt"
                               onClick={e => this.setState({ dt: e.target.checked })}
                               name="dt" checked={this.state.dt} value="dt"/>
                        <label htmlFor="dt"> Detoxification</label><br/>

                        <input type="checkbox" id="mh"
                               onClick={e => this.setState({ mh: e.target.checked })}
                               name="mh" checked={this.state.mh} value="nh"/>
                        <label htmlFor="mh"> Mental health treatment</label><br/>

                        <input type="checkbox" id="hh"
                               onClick={e => this.setState({ hh: e.target.checked })}
                               name="hh" checked={this.state.hh} value="hh"/>
                        <label htmlFor="hh"> Halfway house, or sober home</label><br/>

                        <input type="checkbox" id="ot" name="ot"
                               onClick={e => this.setState({ ot: e.target.checked })}
                               checked={this.state.ot} value="ot"/>
                        <label htmlFor="ot"> Others</label><br/>

                    </div>
                    </div>
                    {/*{this.state.sortedPlaces && this.state.sortedPlaces.map(({ node }) => {*/}
                        {/*return <Place key={node.contentful_id} place={node} />*/}
                    {/*})}*/}
                </div>
                <center>
                    <ListLoading isLoading={this.state.loading} repos={this.state.repos} />
                </center>

            </section>
        )
    }

}

export default PlacesByZipCode