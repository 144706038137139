import React from 'react';
import styles from "../../css/list.module.css";

const List = (props) => {
    const {repos} = props;
    if (!repos || repos.length === 0 || repos === null || repos.errorType == 'TypeError') return <p>No rehabs found, sorry. Please increase the search by adjusting the
        distance.</p>;
    return (
        <ul>
            <h2 className='styles.list-head'>List of Rehabs based on the zip code</h2>
            {repos.map((repo) => {

                return (
                    <li className={styles.list}>
                        <div className={styles.repocontainer}>
                            <span> Facility Name: </span>
                            <span className={styles.repotext}>{repo.name1} </span>
                            <br/>
                            <div className={styles.wrapper}>
                                <div className={styles.address}>

                                <span className={styles.title}> Address: </span>
                                <br/>
                                <span className={styles.repodescription}>{repo.street1}</span>
                                    &nbsp;
                                <span className={styles.repodescription}>{repo.street2}</span>
                                <br/>
                                <span className={styles.repodescription}>{repo.city}, </span>
                                <span className={styles.repodescription}>{repo.state}, </span>
                                <span className={styles.repodescription}>{repo.zip}</span>
                                    &nbsp;
                                <span className={styles.repodescription}>{repo.county}</span>
                                <br/>
                                <span> Phone: </span>
                                <span className={styles.repodescription}>{repo.phone}</span>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <a href={repo.website} target="_blank">Website</a>
                                </div>
                                <div className={styles.features}>
                                    <span className={styles.title}> Facility Type: </span>
                                    <br/>

                                    {repo.sa == 1 ?
                                        (<span className={styles.featuresitems}> Substance use treatment </span>):( <span/>)}
                                    <br/>
                                    {repo.dt == 1 ?
                                        (<span className={styles.featuresitems}> Detoxification </span>):( <span/>)}
                                    <br/>
                                    {repo.hh == 1 ?
                                        (<span className={styles.featuresitems}> Halfway house, or sober home </span>):( <span/>)}
                                    <br/>
                                    {repo.mh == 1 ?
                                        (<span className={styles.featuresitems}> Mental health treatment </span>):( <span/>)}
                                    <br/>
                                    {repo.sumh == 1 ?
                                        (<span className={styles.featuresitems}> Treatment for co-occurring serious mental health illness </span>):( <span/>)}
                                    <br/>
                                </div>
                            </div>
                        </div>

                    </li>
                );
            })}
        </ul>
    );
};
export default List;
